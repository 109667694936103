const axios = require('axios');
const { chatServiceUrl, buildAuthHeaders, chatServiceApiServerlessUrl } = require('./settings.js');

exports.getSettings = function (settingCategory) {
  return axios.get(chatServiceUrl + `/automation/` + settingCategory, {
    headers: buildAuthHeaders()
  });
};

exports.saveSettings = function (settingCategory, settings) {
  return axios.post(chatServiceUrl + '/automation/' + settingCategory, settings, {
    headers: buildAuthHeaders()
  });
};

// Get Active Bot Templates.
exports.getActiveBotTemplates = function (params) {
  return axios.post(chatServiceApiServerlessUrl + '/bot/active-template-list', params, {
    headers: buildAuthHeaders()
  });
};

// Enable Bot Template.
exports.enableBotTemplate = function (params) {
  return axios.post(chatServiceApiServerlessUrl + '/bot/enable-template', params, {
    headers: buildAuthHeaders()
  });
};

// Disable. Bot Template.
exports.disableBotTemplate = function (params) {
  return axios.post(chatServiceApiServerlessUrl + '/bot/disable-template', params, {
    headers: buildAuthHeaders()
  });
};

//#region -------------------- Bot Preview APIs --------------------

// Start Bot Preview.
exports.startBotPreview = function (params) {
  return axios.post(chatServiceApiServerlessUrl + '/bot-preview/start', params, {
    headers: buildAuthHeaders()
  });
};

// Execute Preview Step.
exports.executeBotPreview = function (params) {
  return axios.post(chatServiceApiServerlessUrl + '/bot-preview/execute', params, {
    headers: buildAuthHeaders()
  });
};
