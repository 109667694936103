<template>
  <div id="workingHoursPage" class="mt-5 mb-5 px-2 sm:px-6 lg:px-2">
    <div class="px-4 sm:px-6 lg:px-8">
      <!-- Header -->
      <div class="flex">
        <div class="flex-1">
          <div class="pt-0 text-2xl font-medium">
            <div class="">Greeting & Away messages</div>
            <div class="text-xs pt-1 font-normal text-gray-500">Set your greeting/away messages to be sent to customers inside/outside working hours.</div>
          </div>
        </div>

        <div class="flex-none pl-2 pt-1">
          <el-button type="primary" size="medium" @click="saveChanges" :loading="savingChanges"> Save Changes </el-button>
        </div>
      </div>

      <!-- Loader -->
      <div v-if="loading" class="w-full bg-white border rounded-lg mt-2 overflow-hidden" style="height: 60vh" v-loading="true"></div>

      <div v-else class="mt-4 flex flex-col"></div>

      <!-- Working Hours -->

      <div class="border bg-white rounded-md overflow-hidden">
        <div class="px-4 py-3 font-medium text-gray-800 bg-gray-50">Set your Working Hours</div>

        <div class="py-4 px-4 border-t flex" :key="dayName" v-for="(day, dayName) in workingHours.days">
          <div class="capitalize pt-1.5 text-gray-600 text-sm">{{ dayName }}</div>
          <div class="text-right flex-1 pr-5">
            <el-time-picker format="hh:mm A" size="small" is-range v-model="day.hours" :clearable="false" range-separator="-"> </el-time-picker>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <el-switch v-model="day.enabled"></el-switch>
          </div>
        </div>
      </div>

      <!-- Away Message -->
      <div class="w-full border rounded-md overflow-hidden mt-5">
        <!-- Header -->
        <div class="px-4 py-3 font-medium text-gray-800 bg-gray-50">
          <div class="flex pb-2 pt-1">
            <div class="flex-1">Away Message</div>
            <div>
              <el-switch v-model="awayMessage.enabled"></el-switch>
            </div>
          </div>
          <div class="text-xs font-normal text-gray-400">Let customers know that they have reached you outside business hours. This message is triggered when you receive messages from a number outside the business hours.</div>
        </div>

        <!-- Message -->
        <div class="p-3 bg-white border-t">
          <el-input :disabled="!awayMessage.enabled" type="textarea" :rows="6" placeholder="Enter your message here" v-model="awayMessage.message.text"></el-input>
        </div>

        <!-- Message Interval -->
        <div class="bg-white p-3 border-t">
          <div class="pb-1 text-sm" style="font-weight: 500">Time Interval</div>
          <div class="text-xs font-normal text-gray-400">How often should the Away Message be triggered for incoming messages from the same person? Trigger only once in:</div>
          <div class="pt-4">
            <el-input style="width: 100px !important" size="small" class="inline" :disabled="!awayMessage.enabled" type="number" placeholder="4" v-model="awayMessage.interval"></el-input>
            &nbsp;<span class="text-gray-700 text-base">Hours</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./workingHours.scss"></style>

<script>
import workingHoursComponent from './workingHoursComponent';
export default workingHoursComponent;
</script>
