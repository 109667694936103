import botSettingsService from '@/services/botSettingsService';

export default {
  name: 'WorkingHoursComponent',
  components: {},
  data() {
    let startWorkingHour = new Date(2016, 9, 10, 8, 0);
    let endWorkingHour = new Date(2016, 9, 10, 18, 0);
    return {
      loading: false,
      savingChanges: false,

      // WorkingDay = 'working-hours'
      workingHours: {
        enabled: true,
        days: {
          monday: { enabled: true, hours: [startWorkingHour, endWorkingHour] },
          tuesday: { enabled: true, hours: [startWorkingHour, endWorkingHour] },
          wednesday: { enabled: true, hours: [startWorkingHour, endWorkingHour] },
          thursday: { enabled: true, hours: [startWorkingHour, endWorkingHour] },
          friday: { enabled: true, hours: [startWorkingHour, endWorkingHour] },
          saturday: { enabled: true, hours: [startWorkingHour, endWorkingHour] },
          sunday: { enabled: true, hours: [startWorkingHour, endWorkingHour] }
        },
        timezone: 'Asia/Kolkata'
      },

      awayMessage: {
        enabled: false,
        message: {
          type: 'text',
          text: `We are closed right now. We'll get back to you as soon as we open up tomorrow.`
        },
        interval: 4
      }
    };
  },

  methods: {
    async fetchSettings() {
      try {
        this.loading = true;
        let response = await botSettingsService.getSettings('chat-working-hours');

        let settings = response.data;

        if (settings.workingHours) {
          // Modify Working Hours to it's Date object from start of the day.
          for (let day in settings.workingHours.days) {
            let dayObj = settings.workingHours.days[day];
            let start = new Date(2016, 9, 10, 0, 0);
            start.setMinutes(dayObj.start);
            let end = new Date(2016, 9, 10, 0, 0);
            end.setMinutes(dayObj.end);
            dayObj.hours = [start, end];
          }
          console.log('WorkingHours To Set', settings.workingHours);

          this.workingHours = settings.workingHours;
        }

        if (settings.awayMessage) {
          this.awayMessage = settings.awayMessage;
        }
      } catch (err) {
        this.errorToast('Failed to fetch details. Please contact support.');
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async saveChanges() {
      this.savingChanges = true;
      try {
        // Modify Working Hours to it's minutes from start of the day.
        let toUpdate = JSON.parse(JSON.stringify(this.workingHours));
        for (let day in toUpdate.days) {
          let dayObj = toUpdate.days[day];
          let start = new Date(dayObj.hours[0]);
          let end = new Date(dayObj.hours[1]);
          toUpdate.days[day] = {
            enabled: dayObj.enabled,
            start: start.getHours() * 60 + start.getMinutes(),
            end: end.getHours() * 60 + end.getMinutes()
          };
        }

        let params = {
          workingHours: toUpdate,
          awayMessage: this.awayMessage
        };

        await botSettingsService.saveSettings('chat-working-hours', params);
        this.successToast('Changes saved successfully');
      } catch (err) {
        this.errorToast('Failed to save changes. Please contact support.');
        console.log(err);
      } finally {
        this.savingChanges = false;
      }
    }
  },

  mounted() {
    this.fetchSettings();
  }
};
